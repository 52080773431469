import axios from 'axios';
const { REACT_APP_API_URL } = process.env;
const token = localStorage.getItem('token');

const api = axios.create({
    baseURL: REACT_APP_API_URL,
    headers: {
        token: token 
    }
});

export default api;