import React from 'react';
import './styles.css';
import { Button, Form, FormGroup, Label, Input } from 'reactstrap';
import loginDetailsMissing from '../Toasters/loginDetailsMissing';
import { ToastContainer } from 'react-toastify';
import api from '../../api/config';
import invalidLoginDetails from '../Toasters/invalidLoginDetails';
import { useHistory } from 'react-router';

const Login = () => { 
  
  const history = useHistory();

    const handleSubmit = async (event) => {
  
     
        const email = event.target.email.value;
        const password = event.target.password.value;

        if(email === undefined || password === undefined || email === '' || password === ''){
          loginDetailsMissing();
        } else {

          const loginCredetials = {
            email: email,
            password: password
          }
          
           api.post('/login', loginCredetials).then(result => {

            const token = result.data.token;

            if(token){
              localStorage.setItem('token', token);
              history.push('/');
            } else {
              invalidLoginDetails();
            }
         

          }).catch(err => {
       
            if(err.response.status === 403){
              invalidLoginDetails();
            }

          })
          
        }
        event.preventDefault();
    }

    return (
     <div className = "container"> <br/> <br/> <br/> <br/> <br/> <br/> <br/>

      <h1 id ="adminAreaTitle"> Área de administradores </h1>
      <br/>   <br/>
      <ToastContainer />
      <Form method = "POST" onSubmit = {handleSubmit} >
       <FormGroup>
        <Label for="emailField">E-mail</Label>
        <Input required type="email" name="email" id="emailField" placeholder="Informe seu e-mail de acesso" />
      </FormGroup> <br/>
      <FormGroup>
        <Label for="passwordField">Senha</Label>
        <Input required  type="password" name="password" id="passwordField" placeholder="Informe sua senha" />
      </FormGroup> <br/>


      <Button type = "submit" className = "btn btn-success" id = "loginButton">Acessar</Button>
    </Form>
        </div>
    )
}

export default Login;