import React from 'react';
import './styles.css';
import { Link } from 'react-router-dom';

const Options = (props) => {



    return (
        <div>
            
            <Link to={props.url} className = "optionRedirectGeneral">
                <div className = {props.disabledOption !== true ? 'homeOptions border' : 'disabledHomeOptions border'}> 
                    <br/> <br/>
                    <img alt = "optionIcon" src = {props.image} className = "imageOption"/>
                    <h5 className = "optionName">{props.name}</h5>
                </div>
            </Link>  

        </div>
    )
}

export default Options;