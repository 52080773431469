import React from "react";
import { Switch, BrowserRouter, Route } from 'react-router-dom';
import Home from '../Components/Home/Home';
import Suggestions from '../Components/Suggetions/Suggestions';
import Login from "../Components/Login/Login";
import NotFoundPage from "../Components/NotFoundPage/NotFoundPage";
import Currencies from '../Components/Currencies/Currencies';
import EmailView from "../Components/EmailView";

const Routes = () => {
    return (
        <BrowserRouter>
        
            <Switch>
                <Route   component = {Home} exact path = "/" /> 
                <Route   component = {Suggestions} exact path = "/sugestao" /> 
                <Route   component = {Login} exact path = "/login"/> 
                <Route   component = {Currencies} exact path = "/cotacoes"/> 
                <Route   component = {EmailView} exact path = "/mensagens"/>
                <Route component = {NotFoundPage} />
            </Switch>

        </BrowserRouter>
    )
}

export default Routes;