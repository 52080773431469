import React from 'react';
import Navbar from '../Navbar/Navbar';

const NotFoundPage = () => {
    return (
        <div>
            <Navbar/>

            <div className = "container"> <br/> <br/>
                  <h1> Not found </h1>
            </div>

        </div>
    )
}

export default NotFoundPage;