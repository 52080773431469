import React from 'react';
import Routes from './Router/router';

const App = () => {

  return (
     <Routes/>
  )

}

export default App;
