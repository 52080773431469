import React, { useState } from "react";
import "./styles.css";
import userIcon from '../../assets/person-circle.svg';

import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';

const NavbarComponent = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  return (
    <div>
      <Navbar color="success" dark expand="md">
        <NavbarBrand href="/" className = "titleNavbar">Admin</NavbarBrand>
        <NavbarToggler onClick={toggle} />
        <Collapse isOpen={isOpen} navbar>
          <Nav className="ml-auto" navbar>
            
          <UncontrolledDropdown nav inNavbar>
              <DropdownToggle nav caret>
                <img src = {userIcon} />
              </DropdownToggle>
              <DropdownMenu right>
                <DropdownItem>
                  Sair
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>

          </Nav>
        
        </Collapse>
      </Navbar>
    </div>
  );
};

export default NavbarComponent;
