import React, {useState, useEffect} from 'react';
import './styles.css';
import Navbar from '../Navbar/Navbar';
import { Table } from 'reactstrap';
import api from '../../api/config';
import CurrencyModal from '../CurrencyModal/CurrencyModal';

const Currencies = () => {

    const [currency, setCurrency] = useState();

    useEffect(() => {
        const fetchCurrency = async () => {
            await api.get(`/currency/all`).then((result) => {
                setCurrency(result);
            }).catch(err => {
                console.log(err);
            })
        }

        fetchCurrency();
    }, [])

    const searchInput = (ev) => {
        const currencyToSearch = ev.target.value;

        if(currencyToSearch.length === 0) {
            api.get(`/currency/all`).then(result => {
                setCurrency(result);
            }).catch(err => {
                console.log(err);
            });
        } else {
            api.get(`/currency/include/${currencyToSearch}`).then(result => {
                setCurrency(result);
            }).catch(err => {
                console.log(err);
            });
        }

    
    }

    return (
        <div>
            <Navbar/>
            
            <div className = "container"> <br/> <br/>
                <h4 className = "sectionTitle"> Cotações atuais </h4> <br/>

                <div className = "sectionDiv border"> <br/>

                    <div className = "container">
                       <input onKeyUp = {searchInput} className = "sectionInput" placeholder = "Buscar por nome"></input>
                    </div> 
                   
                    <hr className = "sectionHr"/>

        <Table className = "currencyTable table-borderless">
      <thead >
        <tr>
          <th >Moeda</th>
          <th>Valor</th>
          <th>Código</th>
          <th>Símbulo</th>
          <th></th>
        </tr>
      </thead >

   
      <tbody>

         {currency && (

             (currency.data).map(data => {
                
                if(data){
                   return (
                    <tr className = "table-hover">
                    <td key = {data.currency}>{data.currency}</td>
                    <td key = {data.value}>R$ {data.value}</td>
                    <td key = {data.code}>{data.code}</td>
                    <td key = {data.symbol}>{data.symbol}</td>
                    <td> <CurrencyModal buttonLabel = "Editar"/> </td>
                    <td> <CurrencyModal buttonColor = 'fb4c3c' fontColor = 'white' buttonLabel = "Apagar"/> </td>
                    </tr>
                   )
                }
             })
    
      )}

      </tbody>
    </Table>

                </div>
            </div> <br/> <br/>

           
        </div>
    )
}

export default Currencies;