import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const suggestionApproved = () => {
    toast.success("Sugestão aprovada com sucesso!", {
        autoClose: 3000,
        pauseOnHover: true,
        hideProgressBar: true

      });
}


export default suggestionApproved;