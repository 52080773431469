import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const suggestionRejected = () => {
    toast.success("Sugestão rejeitada com sucesso!", {
        autoClose: 3000,
        pauseOnHover: true,
        hideProgressBar: true

      });
}


export default suggestionRejected;