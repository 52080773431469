import React, {useState, useEffect} from 'react';
import './styles.css';
import api from '../../api/config';
import suggestionApproved from '../Toasters/suggestionApproved.js';
import suggestionRejected from '../Toasters/suggestionRejected';
import pendingSuggestionsError from '../Toasters/pendingSuggestionsError';
import { ToastContainer } from 'react-toastify';
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';
import Navbar from '../Navbar/Navbar';

const Suggestion = () => {

  const [suggestion, setSuggestion] = useState();
  const [page, setPage] = useState(1);
   const [maxPage, setMaxPage] = useState(0);

    useEffect( () => {
       
        const fetchSuggestions = async () => {
     
            await api.get(`/suggestion/pending/page/${page}`, {
                headers: {
                    token: localStorage.getItem('token')
                }
            }).then(suggestions => {

                if(suggestions.status === 200){
                    setSuggestion(suggestions.data);
                    setMaxPage(suggestions.data.maxPages);
                } 

           
            }).catch(err => {
                console.log(err);
                pendingSuggestionsError();
            })
        }
      
        fetchSuggestions();
     
    }, [page]);

    const refreshSuggestions = async () => {
        await api.get(`/suggestion/pending/page/${page}`).then(suggestions => {
      
            if(suggestions.status === 200){
                setSuggestion(suggestions.data);
                setMaxPage(suggestions.data.maxPages);
                
            }

    
        }).catch(err => {
            console.log(err);
        })
    }


    const nextPage = () => {
       setPage(page + 1);
       
       if(page < maxPage){
         setPage(page + 1);
       } else {
         setPage(page);
       }

    }

    const previousPage = () => {

        if(page >= 1){
            setPage(page - 1);
        }
    
    }


    const approveSuggestion = async (id) => {
        await api.put(`/currency/suggestion/${id}`, {status: 'approved'}).then(result => {
            if(result.status === 200){
          

                if(page > maxPage){
                    setPage(page - 1);
                    suggestionApproved();
                    refreshSuggestions();
                } else {
                    suggestionApproved();
                    refreshSuggestions();
                }

            }
        }).catch(err => {
            console.log(err);
        })
    }

    const rejectSuggestion = async (id) => {
        await api.put(`/currency/suggestion/${id}`, {status: 'rejected'}).then(result => {
            if(result.status === 200){
                suggestionRejected();
                refreshSuggestions();
                
            }
        }).catch(err => {
            console.log(err);
        })
    }
        
        

    return (


        <div>
            <Navbar/>

            <div className = "container">  
        <br/>  <br/>  <br/>  
      
        <ToastContainer />
        {
            suggestion && (
                
               
   
             <div  style={{ padding: '.5rem' }}>
                  <h1 id = "title"> Sugestões pendentes <i  id = {suggestion.suggestion.count === 0 ? 'hiddenAmountOfSuggestions' : 'amountOfSuggestions'}> {suggestion.suggestion.count} </i> </h1> 


<p id = 'pagePreview'>Página <i id = "currentPage">{page}</i> <i id = {page <= suggestion.maxPages ? 'maxPagesPreview' : 'hiddenMaxPagesPreview'}> / {suggestion.maxPages} </i></p>
                 
                {/* <button  disabled={page === 1 ? 'true' : ''} onClick = {previousPage} className="btn btn-primary float-left" id = "previousSuggestionsButton"> <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-left" viewBox="0 0 16 16">
  <path fillRule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"/>
</svg>  </button>
                <button disabled = {suggestion.next === true ? false : true} onClick = {nextPage} id = 'nextSuggestionsButton' className="btn btn-primary float-right" ><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-right" viewBox="0 0 16 16">
  <path fillRule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"/>
</svg> </button>   */}

<Pagination aria-label="Page navigation example">
   
      <PaginationItem>
        <PaginationLink disabled={page === 1 ? true : ''} onClick = {previousPage}>
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-left" viewBox="0 0 16 16">
  <path fillRule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"/>
</svg>
        </PaginationLink>
      </PaginationItem>

      <PaginationItem>
        <PaginationLink  disabled = {suggestion.next === true ? false : true} onClick = {nextPage} >
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-right" viewBox="0 0 16 16">
  <path fillRule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"/>
</svg>
        </PaginationLink >
      </PaginationItem>


    </Pagination>



              </div> 


              
            )

        }




        {suggestion && (
     
            (suggestion.suggestion.rows).map(data => {

          
           

                if(data){
                    return (      
                        <div hidden = {false} className = "suggestionField border" key = {data.id}> <br/>  <br/> 
                        <h3 id = "sentBy">Enviada por <i id = "sentByEmail">{data.email}</i></h3>
                        <h1 id = "sugestion"> Sugestão: <i id = "suggestionReceived">{data.suggestion}</i> </h1>  <br/>
                 
                        <button onClick = { () => { rejectSuggestion(data.id) }} id = "denyButton" className = "btn btn-danger"> 
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x-lg" viewBox="0 0 16 16">
                            <path d="M1.293 1.293a1 1 0 0 1 1.414 0L8 6.586l5.293-5.293a1 1 0 1 1 1.414 1.414L9.414 8l5.293 5.293a1 1 0 0 1-1.414 1.414L8 9.414l-5.293 5.293a1 1 0 0 1-1.414-1.414L6.586 8 1.293 2.707a1 1 0 0 1 0-1.414z"/>
                            </svg>
                        </button>  
    
                        <button onClick = { () => { approveSuggestion(data.id) }} id = "approveButton" className = "btn btn-success">  
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-check-lg" viewBox="0 0 16 16">
                        <path d="M13.485 1.431a1.473 1.473 0 0 1 2.104 2.062l-7.84 9.801a1.473 1.473 0 0 1-2.12.04L.431 8.138a1.473 1.473 0 0 1 2.084-2.083l4.111 4.112 6.82-8.69a.486.486 0 0 1 .04-.045z"/>
                        </svg>
                        </button>  
    
                      </div>   
                    
                    )  
                } else {
                    return (
                        <h1> Nothing </h1>
                    )
                }
          
                

          
            })

        )}

     <br/> <br/> <br/>     



   
    <br/> <br/> <br/>  
        
        </div>
        </div>
        

       
    )
}

export default Suggestion;