import React from 'react';
import './styles.css';
import NavbarComponent from '../Navbar/Navbar';

const EmailView = () => {
    return(
        <div>

            <NavbarComponent/>


    <div className="container"> <br/>
    
         
    </div>
   
        
        </div>
    )
}

export default EmailView;