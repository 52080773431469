import React from 'react';
import './styles.css';
import NavbarComponent from '../Navbar/Navbar';
import Options from '../Options/Options';
import graphicIcon from '../../assets/pie-chart-fill.svg';
import currencyIcon from '../../assets/coin.svg';
import lampIcon from '../../assets/lightbulb-fill.svg';
import mail from '../../assets/mail.svg';
import threeDots from '../../assets/three-dots.svg';

const Home = () => {
    return(
        <div>

            <NavbarComponent/>


    <div className="container"> <br/>
    

     <br/> <br/> <br/> 
    
        <div className = "optionsArea">
            <Options url = "/cotacoes" image = {currencyIcon} name = "Cotações"/>
            <Options url = "/sugestao" image = {lampIcon} name = "Sugestões"/>
            <Options url = "graficos" image = {graphicIcon} name = "Gráficos"/>
        </div> <br/> <br/>

        <div className = "optionsArea">
            <Options url = "/mensagens" image = {mail} name = "Mensagens"/>
            <Options url = "/" disabledOption = {true} image = {threeDots} name = "Em breve"/>
            <Options url = "/" disabledOption = {true}image = {threeDots} name = "Em breve"/>
        </div>
         
    </div>
   
        
        </div>
    )
}

export default Home;