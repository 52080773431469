import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const invalidLoginDetails = () => {
    toast.error("E-mail ou senha incorretos, tente novamente!", {
        autoClose: 3000,
        pauseOnHover: true,
        hideProgressBar: true

      });
}


export default invalidLoginDetails;